<template>
  <div class="container">
    <el-radio-group v-model="radioValue"
                    :disabled="isHistory">
      <el-radio :label="1">一级表单</el-radio>
      <el-radio :label="2">二级表单</el-radio>
    </el-radio-group>
    <div v-if="radioValue === 1">
      <el-button type="primary"
                 size="mini"
                 class="mt-15"
                 @click="addField">新增字段</el-button>
      <div draggable="true"
           @dragstart.stop="onDragStart(index)"
           @dragover.stop="onDragover(index)"
           @dragend.stop="onDragend(index)"
           v-for="(item,index) in form_config"
           :key="index"
           class="form-item mt-15"
           :style="changeIndex===index?dragBorder:''">
        <i class="el-icon-s-operation"></i>
        <span class="form-name"
              v-if="!item.edit">{{item.name}}</span>
        <el-input v-else
                  v-model="item.name"
                  style="width: 300px"
                  class="mr-15 ml-15" />
        <el-select v-model="item.type"
                   class="mr-15"
                   placeholder="请选择">
          <el-option v-for="item in typeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary"
                   size="mini"
                   class="mr-15"
                   @click="editField(index)">{{item.edit?'完成':'编辑'}}</el-button>
        <el-button type="primary"
                   size="mini"
                   class="mr-15"
                   @click="delField(index)">删除</el-button>

      </div>
    </div>
    <div v-if="radioValue === 2"
         class="tree">
      <el-button type="primary"
                 size="mini"
                 class="add-btn"
                 @click="addField()">新增一级字段</el-button>
      <div class="tree-item"
           draggable="true"
           v-for="(item,index) in fieldList"
           :key="index"
           :style="changeIndex===index?dragBorder:''"
           @dragstart="onDragStart(index)"
           @dragover="onDragover(index)"
           @dragend="onDragend(index)">
        <div class="tree-field-name">
          <i class="el-icon-s-operation mr-15"></i>
          <span class="mr-15"
                v-if="!item.edit">{{item.name}}</span>
          <el-input v-else
                    v-model="item.name"
                    style="width: 300px"
                    class="mr-15 ml-15" />
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="addField(index)">新增二级字段</el-button>
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="editField(index)">{{item.edit?'完成':'编辑'}}</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="delField(index)">删除</el-button>
        </div>
        <div class="tree-children-content">
          <div draggable="true"
               v-for="(item2,index2) in item.child"
               :key="index2"
               class="tree-children-item"
               @dragstart.stop="onChildDragStart(index,index2)"
               @dragover.stop="onChildDragover(index,index2)"
               @dragend.stop="onChildDragend(index2)"
               :style="(changeChildIndex===index2 && dragFatherIndex === index)?dragChildBorder:''">
            <i class="el-icon-s-operation mr-15"></i>
            <span class="form-name mr-15"
                  v-if="!item2.edit">{{item2.name}}</span>
            <el-input v-else
                      v-model="item2.name"
                      style="width: 300px"
                      class="mr-15 ml-15" />
            <el-select v-model="item2.type"
                       class="mr-15"
                       placeholder="请选择">
              <el-option v-for="type in typeList"
                         :key="type.value"
                         :label="type.label"
                         :value="type.value">
              </el-option>
            </el-select>
            <el-button type="primary"
                       size="mini"
                       class="mr-15"
                       @click="editField(index,index2)">{{item2.edit?'完成':'编辑'}}</el-button>
            <el-button type="primary"
                       size="mini"
                       class="mr-15"
                       @click="delField(index,index2)">删除</el-button>

          </div>
        </div>
      </div>
    </div>
    <el-button type="primary"
               class="mt-15"
               size="mini"
               @click="submit()">保存</el-button>
  </div>
</template>

<script>
import { getPublicForm, setPublicForm } from '@/api/market'
export default {
  data () {
    return {
      typeList: [
        { label: '姓名', value: 'name' },
        { label: '手机号', value: 'tel' },
        { label: '授权手机号', value: 'register_mini_tel' },
        { label: '所在区域', value: 'register_area' },
        { label: '单行文本', value: 'text' }
      ],
      isHistory: false,
      dragFatherIndex: '',
      dragChildIndex: '',
      changeChildIndex: '',
      changeIndex: '',
      dragIndex: '',
      radioValue: 1,
      form_config: [],
      fieldList: []
    }
  },
  components: {},
  computed: {
    dragBorder () {
      if (this.dragIndex !== '' && this.changeIndex !== '') {
        if (this.dragIndex > this.changeIndex) {
          return {
            borderTop: '2px solid #efefef'
          }
        } else if (this.dragIndex < this.changeIndex) {
          return {
            borderBottom: '2px solid #efefef'
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
    dragChildBorder () {
      if (this.dragFatherIndex === '') {
        return {}
      }
      if (this.dragChildIndex !== '' && this.changeChildIndex !== '') {
        if (this.dragChildIndex > this.changeChildIndex) {
          return {
            borderTop: '2px solid #efefef'
          }
        } else if (this.dragChildIndex < this.changeChildIndex) {
          return {
            borderBottom: '2px solid #efefef'
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    addField (index) {
      if (this.radioValue === 1) {
        this.form_config.push({
          name: '新增字段',
          type: 'text',
          edit: false
        })
      } else {
        if (typeof index !== 'undefined') {
          if (this.fieldList[index].child) {
            this.fieldList[index].child.push({
              name: '新增字段',
              type: 'text',
              edit: false
            })
          }
        } else {
          this.fieldList.push({
            name: '新增业务字段',
            edit: false,
            child: []
          })
        }
      }
    },
    editField (index, index2) {
      if (this.radioValue === 1) {
        this.form_config[index].edit = !this.form_config[index].edit
        this.$set(this.form_config, index, this.form_config[index])
      } else {
        if (typeof index2 !== 'undefined') {
          this.fieldList[index].child[index2].edit = !this.fieldList[index].child[index2].edit
          this.$set(this.fieldList, index, this.fieldList[index])
        } else {
          this.fieldList[index].edit = !this.fieldList[index].edit
          this.$set(this.fieldList, index, this.fieldList[index])
        }
      }
    },
    delField (index, index2) {
      if (this.radioValue === 1) {
        this.form_config.splice(index, 1)
      } else {
        if (typeof index2 !== 'undefined') {
          this.fieldList[index].child.splice(index2, 1)
        } else {
          this.fieldList.splice(index, 1)
        }
      }
    },
    onChildDragStart (index, index2) {
      this.dragFatherIndex = index
      this.dragChildIndex = index2
    },
    onChildDragover (index, index2) {
      if (index === this.dragFatherIndex) {
        this.changeChildIndex = index2
      } else {
        this.changeChildIndex = ''
      }
    },
    onChildDragend (index) {
      const item2 = this.fieldList[this.dragFatherIndex].child[index]
      if (index !== this.changeChildIndex) {
        if (index > this.changeChildIndex) {
          this.fieldList[this.dragFatherIndex].child.splice(
            this.changeChildIndex,
            0,
            item2
          )
          this.fieldList[this.dragFatherIndex].child.splice(index + 1, 1)
        } else {
          this.fieldList[this.dragFatherIndex].child.splice(
            this.changeChildIndex + 1,
            0,
            item2
          )
          this.fieldList[this.dragFatherIndex].child.splice(index, 1)
        }
      }
      this.changeChildIndex = ''
      this.dragChildIndex = ''
      this.dragFatherIndex = ''
    },
    onDragStart (index) {
      this.dragIndex = index
    },
    onDragover (index) {
      this.changeIndex = index
    },
    onDragend (index) {
      if (this.radioValue === 1) {
        // const item = this.form_config[this.changeIndex]
        const item2 = this.form_config[index]
        if (index !== this.changeIndex) {
          if (index > this.changeIndex) {
            this.form_config.splice(this.changeIndex, 0, item2)
            this.form_config.splice(index + 1, 1)
          } else {
            this.form_config.splice(this.changeIndex + 1, 0, item2)
            this.form_config.splice(index, 1)
          }
        }
      } else {
        // const item = this.fieldList[this.changeIndex]
        const item2 = this.fieldList[index]
        if (index !== this.changeIndex) {
          if (index > this.changeIndex) {
            this.fieldList.splice(this.changeIndex, 0, item2)
            this.fieldList.splice(index + 1, 1)
          } else {
            this.fieldList.splice(this.changeIndex + 1, 0, item2)
            this.fieldList.splice(index, 1)
          }
        }
      }
      this.changeIndex = ''
    },
    async getPublicForm (id) {
      const res = await getPublicForm({
        activity_id: id
      })
      if (res.meta.code === 0) {
        this.radioValue = res.data.form_type
        this.isHistory = !!res.data.history_form_config
        if (res.data.form_config) {
          if (res.data.form_type === 1) {
            this.form_config = res.data.form_config
            this.form_config.map((item) => {
              item.edit = false
            })
          } else {
            this.fieldList = res.data.form_config
            this.fieldList.map((item) => {
              item.edit = false
              item.child.map((child) => {
                if (child.type === 'el-input') {
                  child.edit = false
                }
              })
            })
          }
        }
      }
    },
    isRepeat (arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true
        }
        hash[arr[i]] = true
      }
      return false
    },
    async setPublicForm (list) {
      const res = await setPublicForm({
        activity_id: this.$route.query.id,
        form_type: this.radioValue,
        form_config: list
      })
      if (res.meta.code === 0) {
        this.$message.success('保存成功')
        this.$router.push({ name: 'Public' })
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async submit () {
      let list = []
      let checkArr = []
      if (this.radioValue === 1) {
        list = [...this.form_config]
        list.map((item) => {
          if (item.type !== 'text') {
            checkArr.push(item.type)
          }
          delete item.edit
        })
        if (this.isRepeat(checkArr)) {
          this.$message.error('表单设置有误，请检查')
        } else {
          this.setPublicForm(list)
        }
      } else {
        list = [...this.fieldList]
        for (const item of list) {
          delete item.edit
          checkArr = []
          for (const item2 of item.child) {
            delete item2.edit
            if (item2.type !== 'text') {
              checkArr.push(item2.type)
            }
          }
          if (this.isRepeat(checkArr)) {
            this.$message.error('表单设置有误，请检查')
            return false
          }
        }
        this.setPublicForm(list)
      }
    }
  },
  mounted () {
    this.getPublicForm(this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 12px;
  .form-item {
    display: flex;
    align-items: center;
    padding: 10px;
    .form-name {
      margin: 0 15px;
      display: inline-block;
    }
  }
  .tree {
    .add-btn {
      margin: 15px 0;
    }
    .tree-item {
      padding: 10px;
    }
    .tree-field-name {
      display: flex;
      align-items: center;
    }
    .tree-children-content {
      padding-left: 20px;
      .tree-children-item {
        padding: 10px;
      }
    }
  }
}
</style>
